import { siteMembersPendingApprovalPageViewSrc5Evid1851 } from '@wix/bi-logger-identity-data/v2';
import { COMPONENT_IDS } from '../../constants';
import model from './model';
import { getPlatformAndOrigin } from '../../utils/biUtils';

export default model.createController(({ $bindAll, $widget, flowAPI }) => {
  const getContext = async () => {
    try {
      const context =
        // @ts-expect-error
        await flowAPI.controllerConfig.wixCodeApi.window.lightbox.getViewerContext();
      return context;
    } catch (error) {
      return undefined;
    }
  };

  const closeLightbox = async () => {
    // @ts-expect-error
    flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
  };

  return {
    pageReady: async ($w) => {
      $widget.fireEvent('widgetLoaded', {});
      flowAPI.bi?.report(
        siteMembersPendingApprovalPageViewSrc5Evid1851({
          ...getPlatformAndOrigin(flowAPI),
        }),
      );

      const context = await getContext();
      $widget.fireEvent('widgetLoaded', {});

      if (context?.email) {
        const emailElement = $w('#email');
        emailElement.text = context.email;
      }
      $w(COMPONENT_IDS.ADMIN_APPROVAL.PRIMARY_BUTTON)?.onClick?.(async () => {
        closeLightbox();
      });
      $w(COMPONENT_IDS.ADMIN_APPROVAL.CLOSE_BUTTON)?.onClick?.(async () => {
        closeLightbox();
      });
    },
    exports: {},
  };
});
