import {
  siteMembersAuthenticationPagesChooseWidgetModeSrc5Evid1789,
  siteMembersForgotPasswordFailureSrc5Evid1865,
  siteMembersForgotPasswordPageViewSrc5Evid1852,
  siteMembersForgotPasswordResetPasswordClickSrc5Evid1853,
  siteMembersForgotPasswordSuccessSrc5Evid1864,
} from '@wix/bi-logger-identity-data/v2';
import { COMPONENT_IDS, STATES } from '../../constants';
import { ServerErrorMessage } from '../../editor-app/editor.app.types';
import { initLocaleKeys } from '../../initLocaleKeys';
import {
  Validators,
  serverErrorsHandler,
  validate,
} from '../../validation/validator';
import model from './model';
import { getPlatformAndOrigin } from '../../utils/biUtils';

export default model.createController(({ $widget, flowAPI, $w }) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const validators = Validators(t);
  const { platform, origin } = getPlatformAndOrigin(flowAPI);

  const clearWidgetState = () => {
    $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR).delete();
  };

  const setServerError = (error: ServerErrorMessage, input?: any) => {
    if (error.shouldDisplayUnderInput) {
      input.inputType = input.inputType;
      return;
    }

    const serverErrorMessageTextElem = $w(
      COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR,
    );
    serverErrorMessageTextElem.setErrorMessage(error.errorMessage);
    serverErrorMessageTextElem.restore();
  };

  const setLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      return $w(
        COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON,
      ).disable();
    }
    $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON).enable();
  };

  const getMetaSiteId = () => {
    return flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId;
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      flowAPI.bi?.report(
        siteMembersForgotPasswordPageViewSrc5Evid1852({
          origin,
          platform,
        }),
      );

      void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
        STATES.FORGOT_PASSWORD.EMAIL,
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR).delete();
      const emailInput = $w(
        COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.EMAIL_INPUT,
      );

      if (!flowAPI.environment.isEditor) {
        setTimeout(() => {
          emailInput.focus();
        }, 100);
      }

      emailInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w(
            COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON,
          ).onClick();
        }
      });

      emailInput.onCustomValidation((value, reject) =>
        validate(value, [validators.isEmail], reject),
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON).onClick(
        async (e) => {
          if (!emailInput.validity.valid) {
            return;
          }
          try {
            flowAPI.bi?.report(
              siteMembersForgotPasswordResetPasswordClickSrc5Evid1853({
                platform,
                email: emailInput.value,
              }),
            );
            setLoadingState(true);
            clearWidgetState();
            // @ts-expect-error
            await flowAPI.controllerConfig.wixCodeApi.members.authentication.sendResetPasswordEmail(
              emailInput.value,
            );
            $w(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.EMAIL).text =
              emailInput.value;
            $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
              STATES.FORGOT_PASSWORD.LINK_SENT,
            );
            flowAPI.bi?.report(
              siteMembersForgotPasswordSuccessSrc5Evid1864({
                platform,
              }),
            );
          } catch (error: any) {
            flowAPI.bi?.report(
              siteMembersForgotPasswordFailureSrc5Evid1865({
                platform,
                failure_reason: error?.message,
              }),
            );
            setServerError(serverErrorsHandler(error, t), emailInput);
          } finally {
            setLoadingState(false);
          }
        },
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.GOT_IT_BUTTON).onClick(
        async () => {
          // @ts-expect-error
          flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
        },
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.CLOSE_BUTTON)?.onClick?.(async () => {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
      });
    },

    updateWidgetViewState: (viewStateId) => {
      flowAPI.bi?.report(
        siteMembersAuthenticationPagesChooseWidgetModeSrc5Evid1789({
          chosen_mode: viewStateId,
          modes: Object.values(STATES.EMAIL_CONFIRMATION).join(','),
          meta_site_id: getMetaSiteId(),
          // widget_name: 'forgot-password',
          // is_editor: flowAPI.environment.isEditor,
        }),
      );
      switch (viewStateId) {
        case STATES.FORGOT_PASSWORD.EMAIL:
          clearWidgetState();
          void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.FORGOT_PASSWORD.EMAIL,
          );
          break;
        case STATES.FORGOT_PASSWORD.ERROR:
          clearWidgetState();
          $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR).restore();
          void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.FORGOT_PASSWORD.EMAIL,
          );
          break;
        case STATES.FORGOT_PASSWORD.LINK_SENT:
          void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.FORGOT_PASSWORD.LINK_SENT,
          );
          break;
      }
    },
    exports: {},
  };
});
