import {
  siteMembersExpiredLinkHomeClickSrc5Evid1860,
  siteMembersExpiredLinkPageViewSrc5Evid1859,
  siteMembersExpiredLinkResetPasswordClickSrc5Evid1861,
} from '@wix/bi-logger-identity-data/v2';
import model from './model';
import { getPlatformAndOrigin } from '../../utils/biUtils';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const { platform, origin } = getPlatformAndOrigin(flowAPI);
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      flowAPI.bi?.report(
        siteMembersExpiredLinkPageViewSrc5Evid1859({
          origin,
          platform,
        }),
      );

      const { user } = flowAPI.controllerConfig.wixCodeApi;

      $w('#expiredTokenLoginButton')?.onClick?.(() => {
        flowAPI.bi?.report(
          siteMembersExpiredLinkResetPasswordClickSrc5Evid1861({
            platform,
          }),
        );
        user.promptLogin({ mode: 'login' });
      });
      $w('#expiredTokenGoToSiteHomeButton')?.onClick?.(() => {
        flowAPI.bi?.report(
          siteMembersExpiredLinkHomeClickSrc5Evid1860({
            platform,
          }),
        );
        flowAPI.controllerConfig.wixCodeApi.location.to?.('/');
      });
    },
    exports: {},
  };
});
