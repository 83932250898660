import { ILocaleKeys } from './locale-keys/LocaleKeys';

export type IScopedPreset = { desktop: string; mobile: string };

export const APP_TOKEN = '';

export const PRESETS = {
  ACCESS_RESTRICTED: {
    desktop: 'variants-l7dfro241',
    mobile: 'variants-l7svr70l',
  },
  EMAIL_CONFIRMATION: {
    desktop: 'variants-l9fcnw00',
    mobile: 'variants-l9fcnvzz',
  },

  ADMIN_APPROVAL: { desktop: 'variants-l9fcjf6v', mobile: 'variants-l9fcjf6w' },
  EXPIRED_TOKEN: { desktop: 'variants-l8a669tl', mobile: 'variants-l8a669tm' },
  RESET_PASSWORD: { desktop: 'variants-legzzrne', mobile: 'variants-lffdhd38' },
  FORGOT_PASSWORD: {
    desktop: 'variants-l9fke7so1',
    mobile: 'variants-lffek0jy',
  },
  ERROR: { desktop: 'variants-lcymr7xu1' },
};

export const ERROR_CODES = {
  PASSWORD_RESETED: '-19973',
  WRONG_AUTH_DETAILS: '-19976',
  ACCESS_DENID: '-19956',
  VALIDATION_ERROR: '-19988',
  WAITING_APPROVAL: '-19958',
  UNKNOWN_ACCOUNT: '-19999',
  WRONG_PASSWORD: '-17005',
  EXISTING_EMAIL_ACCOUNT: '-19995',
  CLIENT_AUTH_FORBIDDEN: '-19974',
  EMAIL_NOT_PROVIDED: '-18880',
  CAPTCHA_REQUIRED: '-19971',
  CAPTCHA_INVALID: '-19970',
  RESET_PASSWORD_TOKEN_EXPIRED: '-19972',
  NEW_RESET_PASSWORD_TOKEN_EXPIRED: 'EXPIRED_JWT_TOKEN',
  REQUEST_THROTTLED: '-19959',
  CODE_INVALID: 'EMAIL_VERIFICATION_REQUIRED',
  BAD_CODE: 'EMAIL_VERIFICATION_FAILED',
  SERVER_EXCEPTION: '-19901',
  AUTHENTICATION_FAILED: '-19976',
  UNIMPLEMENTED_FEATURE: 'UNIMPLEMENTED_FEATURE',
  IP_ADDRESS_BLACKLISTED: 'IP_ADDRESS_FILTERED',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  INVALID_TOKEN: 'INVALID',
  INVALID_SIGNATURE: 'INVALID_SIGNATURE',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  IDENTITY_NOT_ALLOWED_TO_RECOVER: 'IDENTITY_NOT_ALLOWED_TO_RECOVER',
  RECOVERY_TOKEN_DATA_NOT_FOUND: 'RECOVERY_TOKEN_DATA_NOT_FOUND',
  RESOURCE_EXHAUSTED_EXCEPTION: 'RESOURCE_EXHAUSTED_EXCEPTION',
  NOT_FOUND: 'NOT_FOUND',
  BAD_VERIFICATION_CODE: 'BAD_CODE',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  '-19901': '-19901',
  '-19999': '-19999',
};

export const WIDGET_HELP_IDS = {
  CLASSIC: {
    FORGOT_PASSWORD: 'fe5d3d19-7b84-483e-aef2-150986eb434a',
    RESET_PASSWORD: '9a2e679d-f763-41fe-9fc5-6045cb2f5b16',
    ADMIN_APPROVAL: '6cca0a54-b509-4f5c-928d-5ef2a5e0eb09',
    EMAIL_CONFIRMATION: '9a2e679d-f763-41fe-9fc5-6045cb2f5b16',
    EXPIRED_TOKEN: '3c8b97c0-b34b-47f3-8bb0-6adfaf57ef3c',
    ACCESS_RESTRICTED: '94e90b0d-b582-431e-9f6d-fb974972bf2d',
    SUBMISSION_ERROR: '01b06bae-385f-44ec-aa82-3b9978c94f87',
    RESEND_NOTIFICATION: '682a0b38-8fc4-40f6-b059-ab7a2abf08bd',
  },
  STUDIO: {
    FORGOT_PASSWORD: 'fa5434d4-bd29-4a7f-890b-e2dc81afbb71',
    RESET_PASSWORD: 'aa590c2f-7ccf-4468-af93-678ca7fa3102',
    ADMIN_APPROVAL: '815bb8a5-5c79-4058-a533-44aeb20a6751',
    EMAIL_CONFIRMATION: 'da0b640f-649c-4f5d-96f1-8e58359a22f1',
    EXPIRED_TOKEN: 'cb4c38df-18c7-4080-940d-d987cbf07b89',
    ACCESS_RESTRICTED: 'f6c4320c-5b26-482b-b43f-ccdeef639325',
    SUBMISSION_ERROR: '01b06bae-385f-44ec-aa82-3b9978c94f87',
    RESEND_NOTIFICATION: '682a0b38-8fc4-40f6-b059-ab7a2abf08bd',
  },
};

export const LIGHTBOX_IDS = {
  dispatchModal: 'dispatchModal',
  itemModal: 'itemModal',
  errorModal: 'errorModal',
};

export const LIGHTBOX_CONNECTED_PARAMS = {
  CONTROLLER: 'popupController',
  ROLE: 'popupContainer',
};

export const CUSTOM_ACTIONS_IDS = {
  MANAGE_PAGE_PERMISSIONS: 'managePagePermissions',
  MANAGE_MEMBER_ACCESS: 'manageMemberAccess',
  MANAGE_SIGNUP_SETTINGS: 'manageSignupSettings',
  EMAIL_CONFIRAMATION_DESIGN: 'emailConfirmationDesign',
  ACCESS_RESTRICTEDS_SHOW_HIDE_ELEMENTS: 'noPermissionsShowHideElements',
  RESET_PASSWORD_SHOW_HIDE_ELEMENTS: 'resetPasswordShowHideElements',
  FORGOT_PASSWORD_SHOW_HIDE_ELEMENTS: 'forgotPasswordShowHideElements',
  EMAIL_CONFIRMATION_SHOW_HIDE_ELEMENTS: 'emailConfirmationShowHideElements',
  ADMIN_APPROVAL_SHOW_HIDE_ELEMENTS: 'adminApprovalShowHideElements',
  EXPIRED_TOKEN_SHOW_HIDE_ELEMENTS: 'expiredTokenShowHideElements',
  ERROR_MESSAGE_SHOW_HIDE_ELEMENTS: 'errorMessageShowHideElements',
  WARNING_MESSAGE_SHOW_HIDE_ELEMENTS: 'warningMessageShowHideElements',
};

export const PANELS_IDS = {
  FORGOT_PASSWORD: 'q7m2m',
};

export const TPA_PAGES = {
  FORGOT_PASSWORD: 'forgotPasswordTPA',
  RESET_PASSWORD: 'resetPasswordTPA',
  ADMIN_APPROVAL: 'adminApprovalTPA',
  EMAIL_CONFIRMATION: 'emailConfirmationTPA',
  EXPIRED_TOKEN: 'expiredTokenTPA',
  ACCESS_RESTRICTED: 'accessRestrictedTPA',
};

export const COMPONENT_NAMES = {
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  ADMIN_APPROVAL: 'admin-approval',
  EMAIL_CONFIRMATION: 'email-confirmation',
  EXPIRED_TOKEN: 'expired-token',
  ACCESS_RESTRICTED: 'access-restricted',
};

export const COMPONENT_IDS = {
  FORGOT_PASSWORD: {
    CONTAINER: '#box1',
    MULTI_STATE_BOX: '#forgotPasswordState',
    CLOSE_BUTTON: '#closeBtn',
    EMAIL_STEP: {
      CONTAINER: '#reset',
      TITLE: '#resetPasswordTitle',
      SUB_TITLE: '#resetPasswordSubtitle',
      EMAIL_INPUT: '#emailInput',
      SUBMIT_BUTTON: '#submitButton',
      SERVER_ERROR: '#serverErrorMessage',
    },
    LINK_STEP: {
      CONTAINER: '#link',
      TITLE: '#resetPasswordLinkTitle',
      SUB_TITLE: '#resetPasswordLinkSubtitle',
      GOT_IT_BUTTON: '#resetPasswordLinkGotItButton',
      DIDNT_GET_EMAIL_INFO: '#didntGetEmailInfo',
      EMAIL: '#email',
    },
  },
  RESET_PASSWORD: {
    CONTAINER: '#box1',
    MULTI_STATE_BOX: '#createNewPasswordState',
    CREATE_PASSWORD_STEP: {
      TITLE: '#newPasswordTitle',
      PASSWORD_INPUT: '#passwordInput',
      CONFIRM_PASSWORD_INPUT: '#confirmPasswordInput',
      SUBMIT_BUTTON: '#resetPasswordCTA',
      CONTAINER: '#success',
      SERVER_ERROR: '#serverErrorMessage',
    },
    SUCCESS_STEP: {
      TITLE: '#allSetTitle',
      SUB_TITLE: '#allSetSubtitle',
      CLOSE_BUTTON: '#resetPasswordSuccessCloseBtn',
      PRIMARY_BUTTON: '#loginBtn',
      SECONDARY_BUTTON: '#homeBtn',
      CONTAINER: '#createPassword',
    },
  },
  EXPIRED_TOKEN: {
    TITLE: '#expiredTokenTitle',
    SUB_TITLE: '#expiredTokenSubtitle',
    CLOSE_BUTTON: '#expiredTokenCloseBtn',
    SECONDARY_BUTTON: '#expiredTokenGoToSiteHomeButton',
    PRIMARY_BUTTON: '#expiredTokenLoginButton',
    CONTAINER: '#box1',
  },
  EMAIL_CONFIRMATION: {
    TITLE: '#emailConfirmationTitle',
    SUB_TITLE: '#emailConfirmationSubtitle',
    EMAIL: '#email',
    CLOSE_BUTTON: '#emailConfirmationCloseBtn',
    PRIMARY_BUTTON: '#submitButton',
    CONTAINER: '#box1',
    RESEND_TITLE: '#emailConfirmationResendTitle',
    RESEND_TEXT: '#emailConfirmationResendTextLink',
    OTP_INPUT: '#otpInput',
    RESEND_TIMER: '#resendTimer',
    SERVER_ERROR: '#serverErrorMessage',
    WARNING_NOTIFICATION: '#warning',
  },
  ACCESS_RESTRICTEDS: {
    TITLE: '#noPermissionsTitle',
    SUB_TITLE: '#noPermissionsSubtitle',
    CLOSE_BUTTON: '#noPermissionsCloseBtn',
    BACK_BUTTON: '#backButton',
    SWITCH_ACCOUNT_BUTTON: '#switchAccount',
    CONTAINER: '#box1',
  },
  ADMIN_APPROVAL: {
    TITLE: '#adminApprovalTitle',
    SUB_TITLE: '#adminApprovalSubtitle',
    EMAIL: '#email',
    DESCRIPTION: '#adminApprovalDescription',
    CLOSE_BUTTON: '#closeBtn',
    PRIMARY_BUTTON: '#adminApprovalPrimaryButton',
    CONTAINER: '#box1',
  },
  ERROR_MESSAGE: {
    CONTAINER: '#errorMessage',
    TEXT: '#errorText',
    ICON: '#errorIcon',
  },
  WARNING_MESSAGE: {
    CONTAINER: '#warning',
    TEXT: '#warningText',
    ICON: '#warningIcon',
  },
};

export const STATES = {
  FORGOT_PASSWORD: {
    EMAIL: 'reset',
    LINK_SENT: 'link',
    ERROR: 'error',
  },
  RESET_PASSWORD: {
    CREATE_PASSWORD: 'createPassword',
    SUCCESS: 'success',
    ERROR: 'error',
  },
  EMAIL_CONFIRMATION: {
    DEFAULT: 'default',
    ERROR: 'error',
    CODE_RESEND_TIME: 'codeResendTime',
    WARNING: 'warning',
  },
};

export const PAGES_TAB_ACTIONS = {
  DELETE_APP: 'deleteApp',
};

export const ELEMENTS_LABELS_TO_SHOW_RESTORE_NOTIFICATION = [
  'Enter new password',
  'Confirm new password',
  'Create Password',
  'Error msg',
  'Email input',
  'Send Link',
  'Close button',
  'Close',
  'Code input',
  'Resend Code',
  'Resend timer',
  'Confirm Email',
  'Reset Password',
  'Enter 6-digit code',
];

export const getElementsToShowRestoreNotification = (t: ILocaleKeys) => {
  return new Set([
    ...ELEMENTS_LABELS_TO_SHOW_RESTORE_NOTIFICATION,
    t.app.components.resetPassword.displayNames.passwordInput(),
    t.app.components.resetPassword.displayNames.confirmPasswordInput(),
    t.app.components.resetPassword.displayNames.primaryButton(),
    t.app.components.forgotPassword.displayNames.emailInput(),
    t.app.components.forgotPassword.displayNames.closeButton(),
    t.app.components.forgotPassword.displayNames.errorMessages(),
    t.app.components.forgotPassword.displayNames.primaryButton(),
    t.app.components.emailConfirmation.displayNames.otpInput(),
    t.app.components.emailConfirmation.displayNames.submitButton(),
    t.app.components.emailConfirmation.displayNames.errorMessage(),
    t.app.components.emailConfirmation.displayNames.warningMessage(),
    t.app.components.emailConfirmation.displayNames.closeButton(),
    t.app.components.emailConfirmation.displayNames.resendLink(),
    t.app.components.emailConfirmation.displayNames.resendTimer(),
    t.app.components.pendingApproval.displayNames.closeButton(),
    t.app.components.expiredToken.displayNames.loginButton(),
  ]);
};
