import {
  siteMembersAuthenticationPagesChooseWidgetModeSrc5Evid1789,
  siteMembersEmailConfirmationFailureSrc5Evid1863,
  siteMembersEmailConfirmationForNewMembersModalClickResendCode,
  siteMembersEmailConfirmationForNewMembersModalClickSend,
  siteMembersEmailConfirmationForNewMembersModalLoad,
  siteMembersEmailConfirmationSuccessSrc5Evid1862,
} from '@wix/bi-logger-identity-data/v2';
import { STATES } from '../../constants';
import { ServerErrorMessage } from '../../editor-app/editor.app.types';
import { initLocaleKeys } from '../../initLocaleKeys';
import {
  Validators,
  serverErrorsHandler,
  validate,
} from '../../validation/validator';
import model from './model';
import { getPlatformAndOrigin } from '../../utils/biUtils';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const validators = Validators(t);
  const { platform, origin } = getPlatformAndOrigin(flowAPI);

  const clearWidgetState = () => {
    $w('#serverErrorMessage').delete();
    $w('#warning').delete();
    $w('#resendTimer').delete();
    $w('#emailConfirmationResendTextLink').restore();
  };
  const handleResendTimer = async (cb) => {
    const TIMER = 30;
    return new Promise<void>((resolve) => {
      let timeLeft = TIMER;
      cb(timeLeft);
      const countdown = setInterval(() => {
        timeLeft--;
        const timeLeftStr = timeLeft < 10 ? `0${timeLeft}` : `${timeLeft}`;
        cb(timeLeftStr);
        if (timeLeft < 1) {
          clearInterval(countdown);
          timeLeft = TIMER;
          resolve();
        }
      }, 1000);
    });
  };
  const setServerError = (error: ServerErrorMessage, input?: any) => {
    if (error.shouldDisplayUnderInput) {
      input.inputType = input.inputType;
      return;
    }
    const serverErrorMessageTextElem = $w('#serverErrorMessage');
    serverErrorMessageTextElem.setErrorMessage(error.errorMessage);
    serverErrorMessageTextElem.restore();
  };

  const setLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      return $w('#submitButton').disable();
    }
    $w('#submitButton').enable();
  };

  const getMetaSiteId = () => {
    return flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId;
  };

  const getContext = async () => {
    try {
      const context =
        // @ts-expect-error
        await flowAPI.controllerConfig.wixCodeApi.window.lightbox.getViewerContext();
      return context;
    } catch (error) {
      return undefined;
    }
  };
  return {
    pageReady: async () => {
      flowAPI.bi?.report(
        siteMembersEmailConfirmationForNewMembersModalLoad({
          ...getPlatformAndOrigin(flowAPI),
        }),
      );
      clearWidgetState();

      const context = await getContext();
      const email = context?.email;
      const template = $w('#resendTimer').children[1].html;
      $widget.fireEvent('widgetLoaded', {});

      if (email) {
        try {
          const emailConfirmationSubtitleElement = $w('#email');
          emailConfirmationSubtitleElement.text = email;
        } catch (error) {
          console.error('Failed to set email confirmation subtitle', error);
        }
      }

      const otpInput = $w('#otpInput');
      otpInput.onCustomValidation((value, reject) =>
        validate(value, [validators.minLength(6)], reject),
      );

      otpInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w('#submitButton').onClick();
        }
      });

      if (!flowAPI.environment.isEditor) {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
        setTimeout(() => {
          otpInput.focus();
        }, 100);
      }

      $w('#submitButton').onClick(async (e) => {
        if (!otpInput.validity.valid) {
          return;
        }
        try {
          flowAPI.bi?.report(
            siteMembersEmailConfirmationForNewMembersModalClickSend({
              platform,
            }),
          );
          setLoadingState(true);
          clearWidgetState();
          // @ts-expect-error
          await flowAPI.controllerConfig.wixCodeApi.members.authentication.verifyEmail(
            otpInput.value,
          );
          flowAPI.bi?.report(
            siteMembersEmailConfirmationSuccessSrc5Evid1862({
              platform,
            }),
          );
          // @ts-expect-error
          flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
        } catch (error: any) {
          flowAPI.bi?.report(
            siteMembersEmailConfirmationFailureSrc5Evid1863({
              platform,
              failure_reason: error?.message,
            }),
          );
          setServerError(serverErrorsHandler(error, t), otpInput);
        } finally {
          setLoadingState(false);
        }
      });

      // handle resend otp
      $w('#emailConfirmationResendTextLink').onClick(async (e) => {
        try {
          flowAPI.bi?.report(
            siteMembersEmailConfirmationForNewMembersModalClickResendCode({
              platform,
            }),
          );

          // @ts-expect-error
          flowAPI.controllerConfig.wixCodeApi.members.authentication
            .resendVerificationCodeEmail()
            .catch((error) => {
              setServerError(serverErrorsHandler(error, t));
            });

          $w('#emailConfirmationResendTextLink').delete();
          $w('#serverErrorMessage').delete();
          $w('#resendTimer').restore();

          await handleResendTimer((timeLeft) => {
            $w('#resendTimer').children[1].html = template.replace(
              '{30}',
              `${timeLeft}`,
            );
          });
          $w('#resendTimer').delete();
          $w('#emailConfirmationResendTextLink').restore();
        } catch (error: any) {
          setServerError(serverErrorsHandler(error, t));
        }
      });
      $w('#emailConfirmationCloseBtn').onClick(() => {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
        flowAPI.controllerConfig.wixCodeApi.user.promptLogin({
          mode: 'signup',
        });
      });
    },
    updateWidgetViewState: (viewStateId) => {
      flowAPI.bi?.report(
        siteMembersAuthenticationPagesChooseWidgetModeSrc5Evid1789({
          chosen_mode: viewStateId,
          modes: Object.values(STATES.EMAIL_CONFIRMATION).join(','),
          meta_site_id: getMetaSiteId(),
          // widget_name: 'email-confirmation',
          // is_editor: flowAPI.environment.isEditor,
        }),
      );
      switch (viewStateId) {
        case STATES.EMAIL_CONFIRMATION.ERROR:
          clearWidgetState();
          $w('#serverErrorMessage').restore();
          break;
        case STATES.EMAIL_CONFIRMATION.WARNING:
          clearWidgetState();
          $w('#warning').restore();
          break;
        case STATES.EMAIL_CONFIRMATION.CODE_RESEND_TIME:
          clearWidgetState();
          $w('#emailConfirmationResendTextLink').delete();
          $w('#resendTimer').restore();
          break;
        default:
          clearWidgetState();
          break;
      }
    },

    exports: {},
  };
});
