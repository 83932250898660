import { COMPONENT_IDS } from '../../constants';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const setErrorMessage = (errorMessage: string) => {
    console.error(errorMessage);
    const serverErrorMessageTextElem = $w(COMPONENT_IDS.ERROR_MESSAGE.TEXT);
    serverErrorMessageTextElem.text = errorMessage;
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setErrorMessage,
    },
  };
});
