import {
  siteMembersAuthenticationPagesChooseWidgetModeSrc5Evid1789,
  siteMembersNewPasswordCreatePasswordClickSrc5Evid1855,
  siteMembersNewPasswordFailureSrc5Evid1867,
  siteMembersNewPasswordPageViewSrc5Evid1854,
  siteMembersNewPasswordSuccessSrc5Evid1866,
} from '@wix/bi-logger-identity-data/v2';
import { COMPONENT_IDS, ERROR_CODES, STATES } from '../../constants';
import { ServerErrorMessage } from '../../editor-app/editor.app.types';
import { initLocaleKeys } from '../../initLocaleKeys';
import {
  Validators,
  extractErrorCode,
  serverErrorsHandler,
  validate,
  validateInputs,
} from '../../validation/validator';
import model from './model';
import { getPlatformAndOrigin } from '../../utils/biUtils';

export default model.createController(({ $widget, flowAPI, $w }) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const validators = Validators(t);
  const { platform, origin } = getPlatformAndOrigin(flowAPI);
  const clearWidgetState = () => {
    $w(
      COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
    )?.delete();
  };

  $w(COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR)?.delete();

  const setServerError = (error: ServerErrorMessage, input?: any) => {
    if (error.shouldDisplayUnderInput) {
      input.inputType = input.inputType;
      return;
    }

    const serverErrorMessageTextElem = $w(
      COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
    );
    serverErrorMessageTextElem.setErrorMessage(error.errorMessage);
    serverErrorMessageTextElem.restore();
  };

  const setLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      return $w('#resetPasswordCTA').disable();
    }
    $w('#resetPasswordCTA').enable();
  };

  const getMetaSiteId = () => {
    return flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId;
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      flowAPI.bi?.report(
        siteMembersNewPasswordPageViewSrc5Evid1854({
          origin,
          platform,
        }),
      );

      const passwordInput = $w(
        COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.PASSWORD_INPUT,
      );

      if (!flowAPI.environment.isEditor) {
        setTimeout(() => {
          passwordInput.focus();
        }, 100);
      }

      passwordInput.onCustomValidation((value, reject) =>
        validate(value, [validators.minLength(6)], reject),
      );

      passwordInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w(
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SUBMIT_BUTTON,
          ).onClick();
        }
      });

      const verifyPasswordInput = $w(
        COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP
          .CONFIRM_PASSWORD_INPUT,
      );

      verifyPasswordInput.onCustomValidation((value, reject) =>
        validate(
          value,
          [validators.matches(passwordInput), validators.isRequired],
          reject,
        ),
      );

      verifyPasswordInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w(
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SUBMIT_BUTTON,
          ).onClick();
        }
      });

      $w('#resetPasswordCTA').onClick(async (e) => {
        if (
          !passwordInput.validity.valid ||
          !verifyPasswordInput.validity.valid
        ) {
          validateInputs([passwordInput, verifyPasswordInput]);
          return;
        }
        const newPassword = passwordInput.value;
        try {
          flowAPI.bi?.report(
            siteMembersNewPasswordCreatePasswordClickSrc5Evid1855({
              platform,
            }),
          );
          setLoadingState(true);
          clearWidgetState();
          const { resetPasswordToken } =
            flowAPI.controllerConfig.wixCodeApi.window.getAppPageData<any>();
          // @ts-expect-error
          await flowAPI.controllerConfig.wixCodeApi.members.authentication.changePassword(
            newPassword,
            resetPasswordToken,
          );
          flowAPI.bi?.report(
            siteMembersNewPasswordSuccessSrc5Evid1866({
              platform,
            }),
          );
          $w('#createNewPasswordState').changeState('success');
        } catch (error: any) {
          flowAPI.bi?.report(
            siteMembersNewPasswordFailureSrc5Evid1867({
              platform,
              failure_reason: error?.message,
            }),
          );
          const code = extractErrorCode(error);
          if (
            code === ERROR_CODES.RECOVERY_TOKEN_DATA_NOT_FOUND ||
            code === ERROR_CODES.NEW_RESET_PASSWORD_TOKEN_EXPIRED
          ) {
            return flowAPI.controllerConfig.wixCodeApi.location.to?.(
              '/auth/expired-token',
            );
          }
          setServerError(serverErrorsHandler(error, t), passwordInput);
        } finally {
          setLoadingState(false);
        }
      });
      $w('#loginBtn').onClick(() => {
        flowAPI.controllerConfig.wixCodeApi.user.promptLogin({});
      });

      const homePage = (
        await flowAPI.controllerConfig.wixCodeApi.site.getSiteStructure({
          includePageId: true,
        })
      ).pages.find((page) => page.isHomePage);
      $w('#homeBtn')?.onClick?.(() => {
        flowAPI.controllerConfig.wixCodeApi.location.navigateTo?.({
          ...homePage,
          type: 'PageLink',
          pageId: homePage?.id,
        });
      });
      $w('#errorBox')?.delete?.();
    },
    updateWidgetViewState: (viewStateId) => {
      flowAPI.bi?.report(
        siteMembersAuthenticationPagesChooseWidgetModeSrc5Evid1789({
          chosen_mode: viewStateId,
          modes: Object.values(STATES.EMAIL_CONFIRMATION).join(','),
          meta_site_id: getMetaSiteId(),
          // widget_name: 'reset-password',
          // is_editor: flowAPI.environment.isEditor,
        }),
      );
      switch (viewStateId) {
        case STATES.RESET_PASSWORD.ERROR:
          clearWidgetState();
          $w(
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
          ).restore();
          void $w(COMPONENT_IDS.RESET_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.RESET_PASSWORD.CREATE_PASSWORD,
          );
          break;
        case STATES.RESET_PASSWORD.SUCCESS:
          clearWidgetState();
          void $w(COMPONENT_IDS.RESET_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.RESET_PASSWORD.SUCCESS,
          );
          break;
        case STATES.RESET_PASSWORD.CREATE_PASSWORD:
          clearWidgetState();
          void $w(COMPONENT_IDS.RESET_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.RESET_PASSWORD.CREATE_PASSWORD,
          );
          break;
      }
    },
    exports: {},
  };
});
