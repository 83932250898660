import { COMPONENT_IDS } from '../../constants';
import { getPlatformAndOrigin } from '../../utils/biUtils';
import model from './model';
import {
  siteMembersAccessRestrictedPageViewSrc5Evid1856,
  siteMembersAccessRestrictedSwitchAccountClickSrc5Evid1849,
  siteMembersForgotPasswordResetPasswordClickCopySrc5Evid1857,
} from '@wix/bi-logger-identity-data/v2';

export default model.createController(({ $widget, flowAPI, $w }) => {
  const { platform, origin } = getPlatformAndOrigin(flowAPI);
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      flowAPI.bi?.report(
        siteMembersAccessRestrictedPageViewSrc5Evid1856({
          origin,
          platform,
        }),
      );

      $w(COMPONENT_IDS.ACCESS_RESTRICTEDS.SWITCH_ACCOUNT_BUTTON)?.onClick?.(
        () => {
          flowAPI.bi?.report(
            siteMembersAccessRestrictedSwitchAccountClickSrc5Evid1849({
              platform,
            }),
          );
          flowAPI.controllerConfig.wixCodeApi.user.promptLogin({
            mode: 'login',
          });
        },
      );

      $w(COMPONENT_IDS.ACCESS_RESTRICTEDS.BACK_BUTTON)?.onClick?.(() => {
        flowAPI.bi?.report(
          siteMembersForgotPasswordResetPasswordClickCopySrc5Evid1857({
            platform,
          }),
        );
        flowAPI.controllerConfig.wixCodeApi.location.to?.('/');
      });
    },
    exports: {},
  };
});
